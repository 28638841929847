<template>
  <tr :class="['TableRow', isHeaderRow && 'TableRow--header', clickable && 'TableRow--clickable']">
    <slot name="default" />
  </tr>
</template>

<script>
export default {
  name: 'TableRow',

  props: {
    item: {
      type: Object,
      default: undefined,
    },
    isHeaderRow: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    clickable() {
      return !this.isHeaderRow && this.table.clickable;
    },
  },

  inject: {
    table: {
      default() {
        console.error('Missing Table');
        return;
      },
    },
  },

  provide() {
    return {
      tableRow: this,
    };
  },
};
</script>

<style lang="scss" scoped>
.TableRow {
  background-color: $color-white;
  &:not(.TableRow--header) {
    border-top: 1px solid #efefef;
  }
}

.TableRow--clickable:hover {
  background-color: $color-prim-grey-light;
  cursor: pointer;
}
</style>
