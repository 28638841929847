<template>
  <Popover toggleOnClick>
    <template #default>
      <slot name="default" />
    </template>
    <template #content>
      <div class="XalCartPopover__">
        <div class="XalCartPopover__header">
          <span class="XalCartPopover__headerTitle">Indkøbskurv</span>
          <div class="XalCartPopover__headerStatus">
            <ProgressStatus
              class="XalCartPopover__progressStatus"
              :progress="updateStatus"
              completeLabel="Gemt!"
              workingLabel="Gemmer.."
            />
            <ProgressIndicator
              class="XalCartPopover__progressIndicator"
              :progress="status"
              :text="progressText(status)"
            />
          </div>
        </div>
        <Table
          v-if="lines && lines.length"
          itemKey="line_id"
          class="XalCartPopover__table"
          :items="lines"
        >
          <TableColumn v-slot="{ item }">
            <OrderLineCard :line="item" />
          </TableColumn>
          <TableColumn v-slot="{ item }">
            <input
              v-if="item.item_number"
              :value="item.quantity"
              :disabled="status !== Progress.COMPLETE"
              @blur="
                item.quantity !== +$event.target.value &&
                  updateLine({ line_id: item.line_id, quantity: +$event.target.value })
              "
              @keyup.enter="
                item.quantity !== +$event.target.value &&
                  updateLine({ line_id: item.line_id, quantity: +$event.target.value })
              "
              name="amount"
              type="number"
              pattern="[0-9]"
              step="1"
              maxlength="4"
              min="1"
              class="XalCartPopover__input"
            />
          </TableColumn>
          <TableColumn v-slot="{ item }">
            <Button
              :color="colors.RED"
              :size="sizes.SMALL"
              :icon="icons.TRASH"
              :disabled="status !== Progress.COMPLETE"
              @click="removeLine({ item_id: item.item_id, item_number: item.item_number })"
              tabindex="-1"
            >
              Fjern
            </Button>
          </TableColumn>
        </Table>
        <span v-else class="XalCartPopover__emptyCartLabel">Kurven er tom</span>
      </div>
    </template>
  </Popover>
</template>

<script>
import Progress from '@types/Progress';
import Popover from '@components/Popover';
import { Table, TableColumn } from '@components/Table';
import OrderLineCard from '@components/OrderLineCard';
import { Button, colors, sizes, icons } from '@components/Buttons';
import ProgressIndicator from '@components/ProgressIndicator';
import ProgressStatus from '@components/ProgressStatus';

let inputTimeoutUpdateProfile;

export default {
  name: 'XalCartPopover',

  components: {
    Popover,
    Button,
    Table,
    TableColumn,
    OrderLineCard,
    ProgressIndicator,
    ProgressStatus,
  },

  enums: {
    Progress,
    sizes,
    colors,
    icons,
  },

  computed: {
    lines() {
      return this.XalCartContainer.lines;
    },
    status() {
      return this.XalCartContainer.status;
    },
    updateStatus() {
      return this.XalCartContainer.updateStatus;
    },
    itemCount() {
      return this.XalCartContainer.itemCount;
    },
    removeLine() {
      return this.XalCartContainer.removeLine;
    },
    updateLine() {
      return this.XalCartContainer.updateLine;
    },
  },

  methods: {
    debounceInput(callback) {
      clearTimeout(inputTimeoutUpdateProfile);
      inputTimeoutUpdateProfile = setTimeout(callback, 600);
    },
    progressText(status) {
      switch (status) {
        case Progress.WORKING:
          return 'Forbinder til Xal..';
        case Progress.COMPLETE:
          return 'Forbundet til Xal';
        case Progress.ERROR:
          return 'Forbindelsesfejl til Xal';
        default:
          return 'Ingen forbindelse til Xal';
      }
    },
  },

  inject: {
    XalCartContainer: {
      default() {
        console.error('Missing XalCartContainer');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.XalCartPopover__ {
  display: flex;
  flex-direction: column;
  padding: 10px;

  .XalCartPopover__header {
    display: flex;
    justify-content: space-between;
    gap: 60px; // minimum gap
  }

  .XalCartPopover__headerTitle {
    font-size: 16px;
  }

  .XalCartPopover__headerStatus {
    display: flex;
    gap: 20px;
  }

  .XalCartPopover__progressIndicator,
  .XalCartPopover__progressStatus {
    font-size: 12px;
  }

  .XalCartPopover__progressStatus {
    height: 1.33em;
    min-width: 80px;
  }

  .XalCartPopover__table {
    max-height: 80vh;
  }

  .XalCartPopover__emptyCartLabel {
    border-top: 1px solid #efefef;
    margin-top: 15px;
    padding: 5px;
    padding-top: 20px;
    text-align: center;
  }

  .XalCartPopover__input {
    width: 60px;
    height: 30px;
    padding-left: 1em;
    border: 1px solid #efefef;
    text-align: center;
  }
}
</style>
