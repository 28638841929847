<template>
  <component
    :is="isInHeaderRow ? 'th' : 'td'"
    :class="[
      'TableColumn',
      isInHeaderRow && `TableColumn--header`,
      activeAlignment && `TableColumn--${activeAlignment}`,
      strong && `TableColumn--strong`,
    ]"
  >
    <div class="TableColumn__inner">
      <template v-if="isInHeaderRow">{{ header }}</template>
      <template v-else>
        <slot name="default" :item="item">
          <template v-if="format === ColumnFormat.PRICE">
            <Price :value="itemProperty" />
          </template>
          <template v-else>{{ itemProperty }}</template>
        </slot>
      </template>
    </div>
  </component>
</template>

<script>
import { getProperty } from '@helpers/object';
import Price from '@components/Price';
import { ColumnAlignment, ColumnFormat } from '../../types';

export default {
  name: 'TableColumn',

  components: {
    Price,
  },

  enums: {
    ColumnFormat,
  },

  props: {
    header: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    format: {
      type: String,
      default: '',
    },
    alignment: {
      type: String,
      default: '',
    },
    strong: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    activeAlignment() {
      return this.alignment || this.table.alignment || ColumnAlignment.LEFT;
    },
    item() {
      return this.tableRow.item;
    },
    itemProperty() {
      return getProperty(this.item, this.value);
    },
    isInHeaderRow() {
      return this.tableRow.isHeaderRow;
    },
  },

  inject: {
    table: {
      default() {
        console.error('Missing Table');
        return;
      },
    },
    tableRow: {
      default() {
        console.error('Missing TableRow');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.TableColumn {
  vertical-align: top;
  white-space: nowrap;

  .TableColumn__inner {
    display: flex;
    padding: 15px 10px;
  }

  &--header .TableColumn__inner {
    color: $color-prim-grey-dark;
  }

  &--left .TableColumn__inner {
    justify-content: flex-start;
  }

  &--right .TableColumn__inner {
    justify-content: flex-end;
  }

  &--strong .TableColumn__inner {
    font-weight: bold;
  }
}
</style>
