<template>
  <PopoverHandler
    :toggleOnClick="toggleOnClick"
    :locked="locked"
    :disabled="disabled"
    :class="['Popover', `Popover--${position}`]"
    @open="onOpen"
    @close="onClose"
  >
    <template #default>
      <div class="Popover__target">
        <slot name="default" />
      </div>
    </template>
    <template #content="{ close }">
      <div class="Popover__arrow" />
      <div class="Popover__arrowShadow" />
      <div ref="content" :style="style" class="Popover__content">
        <slot name="content" :close="close" />
      </div>
    </template>
  </PopoverHandler>
</template>

<script>
import PopoverHandler from '@components/PopoverHandler';

export const Orientation = {
  VERTICAL: 'VERTICAL',
  HORIZONTAL: 'HORIZONTAL',
};

const Position = {
  TOP: 'top',
  BOTTOM: 'bottom',
  RIGHT: 'right',
  LEFT: 'left',
};

export default {
  name: 'Popover',

  components: {
    PopoverHandler,
  },

  props: {
    orientation: {
      type: String,
      default: Orientation.VERTICAL,
    },
    toggleOnClick: {
      type: Boolean,
      default: false,
    },
    locked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      position: this.getInitialPosition(),
      style: '',
    };
  },

  computed: {
    isMobileSize() {
      return ['mobileS', 'mobileM', 'mobileL'].includes(this.$mq);
    },
    viewportPadding() {
      return this.isMobileSize ? 10 : 25;
    },
  },

  created() {
    this.targetMargin = 12;
    this.viewport = undefined;
    this.document = undefined;
    this.target = undefined;
    this.content = undefined;
    this.border = undefined;
  },

  beforeDestroy() {
    this.removeEventListeners();
  },

  methods: {
    onOpen() {
      this.$nextTick(this.updateOnOpen);
      this.addEventListeners();
      this.$emit('open');
    },
    onClose() {
      this.removeEventListeners();
      this.$emit('close');
    },
    addEventListeners() {
      window.addEventListener('resize', this.update);
      window.addEventListener('scroll', this.update);
    },
    removeEventListeners() {
      window.removeEventListener('resize', this.update);
      window.removeEventListener('scroll', this.update);
    },
    getInitialPosition() {
      switch (this.orientation) {
        case Orientation.VERTICAL:
          return Position.BOTTOM;
        case Orientation.HORIZONTAL:
          return Position.RIGHT;
        default:
          return Position.BOTTOM;
      }
    },
    updateOnOpen() {
      this.updateShapeValues();
      this.updatePosition();
      this.updateAlignment();
    },
    update() {
      // debounce afhængig af om window.scrollX eller window.scrollY ændres
      this.updateShapeValues();
      this.updateAlignment();
    },
    updateShapeValues() {
      this.viewport = this.getViewportRect();
      this.document = this.getRect(document.documentElement);
      this.target = this.getRect(this.$el);
      this.content = this.getRect(this.$refs.content);
      this.border = this.getBorderRect();
    },
    getViewportRect() {
      const top = this.viewportPadding,
        bottom = window.innerHeight - this.viewportPadding,
        left = this.viewportPadding,
        right = window.innerWidth - this.viewportPadding,
        width = right - left,
        height = bottom - top,
        centerX = left + width / 2,
        centerY = top + height / 2;
      return { top, bottom, left, right, width, height, centerX, centerY };
    },
    getRect(element) {
      const rect = element.getBoundingClientRect();
      return {
        top: rect.top,
        bottom: rect.bottom,
        left: rect.left,
        right: rect.right,
        width: rect.width,
        height: rect.height,
        centerX: rect.left + rect.width / 2,
        centerY: rect.top + rect.height / 2,
      };
    },
    getBorderRect() {
      const top = this.document.top,
        bottom = this.document.bottom,
        left = this.viewport.left,
        right = this.viewport.right,
        width = right - left,
        height = bottom - top,
        centerX = left + width / 2,
        centerY = top + height / 2;
      return { top, bottom, left, right, width, height, centerX, centerY };
    },
    updatePosition() {
      if (this.orientation === Orientation.VERTICAL) this.updateVerticalPosition();
      else if (this.orientation === Orientation.HORIZONTAL) this.updateHorizontalPosition();
    },
    updateVerticalPosition() {
      const inUpperHalf = this.target.centerY < this.viewport.centerY;
      const contentTop = this.target.top - this.targetMargin - this.content.height;
      const overflowsBorderTop = contentTop < this.border.top;
      this.position = inUpperHalf || overflowsBorderTop ? Position.BOTTOM : Position.TOP;
    },
    updateHorizontalPosition() {
      const inLeftHalf = this.target.centerX < this.viewport.centerX;
      const contentLeft = this.target.left - this.targetMargin - this.content.width;
      const overflowsBorderLeft = contentLeft < this.border.left;
      this.position = inLeftHalf || overflowsBorderLeft ? Position.RIGHT : Position.LEFT;
    },
    updateAlignment() {
      if (this.position === Position.TOP || this.position === Position.BOTTOM)
        this.updateHorizontalAlignment();
      else if (this.position === Position.RIGHT || this.position === Position.LEFT)
        this.updateVerticalAlignment();
    },
    updateHorizontalAlignment() {
      const contentLeft = this.target.centerX - this.content.width / 2;
      const contentRight = this.target.centerX + this.content.width / 2;
      const overflowsBorderLeft = contentLeft < this.border.left;
      const overflowsBorderRight = contentRight > this.border.right;
      if (overflowsBorderLeft) this.alignBorderLeft();
      else if (overflowsBorderRight) this.alignBorderRight();
      else this.clearAlignment();
    },
    updateVerticalAlignment() {
      const contentTop = this.target.centerY - this.content.height / 2;
      const contentBottom = this.target.centerY + this.content.height / 2;
      const overflowsBorderTop = contentTop < this.border.top;
      const overflowsBorderBottom = contentBottom > this.border.bottom;
      if (overflowsBorderTop) this.alignBorderTop();
      else if (overflowsBorderBottom) this.alignBorderBottom();
      else this.clearAlignment();
    },
    alignBorderLeft() {
      const contentLeft = this.target.centerX - this.content.width / 2;
      const distanceToBorderLeft = this.border.left - contentLeft;
      const halfContentWidth = this.content.width / 2 - 10;
      const offsetX = Math.min(distanceToBorderLeft, halfContentWidth);
      const offsetY = this.position === Position.TOP ? '-100%' : '0';
      this.style = `transform: translate3d(calc(${offsetX}px - 50%), ${offsetY}, 0)`;
    },
    alignBorderRight() {
      const contentLeft = this.target.centerX - this.content.width / 2;
      const contentRight = this.target.centerX + this.content.width / 2;
      const distanceToBorderRight = contentRight - this.border.right;
      const distanceToBorderLeft = contentLeft - this.border.left;
      const halfContentWidth = this.content.width / 2 - 10;
      const offsetX = Math.min(distanceToBorderRight, distanceToBorderLeft, halfContentWidth);
      const offsetY = this.position === Position.TOP ? '-100%' : '0';
      this.style = `transform: translate3d(calc(-${offsetX}px - 50%), ${offsetY}, 0)`;
    },
    alignBorderTop() {
      const contentTop = this.target.centerY - this.content.height / 2;
      const distanceToBorderTop = this.border.top - contentTop;
      const halfContentHeight = this.content.height / 2 - 10;
      const offsetX = this.position === Position.LEFT ? '-100%' : '0';
      const offsetY = Math.min(distanceToBorderTop, halfContentHeight);
      this.style = `transform: translate3d(${offsetX}, calc(${offsetY}px - 50%), 0)`;
    },
    alignBorderBottom() {
      const contentTop = this.target.centerY - this.content.height / 2;
      const contentBottom = this.target.centerY + this.content.height / 2;
      const distanceToBorderBottom = contentBottom - this.border.bottom;
      const distanceToBorderTop = contentTop - this.border.top;
      const halfContentHeight = this.content.height / 2 - 10;
      const offsetX = this.position === Position.LEFT ? '-100%' : '0';
      const offsetY = Math.min(distanceToBorderBottom, distanceToBorderTop, halfContentHeight);
      this.style = `transform: translate3d(${offsetX}, calc(-${offsetY}px - 50%), 0)`;
    },
    clearAlignment() {
      this.style = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.Popover {
  position: relative;
  display: inline-block;

  .Popover__target {
    position: relative;
    z-index: 1;
  }

  .Popover__arrow,
  .Popover__arrowShadow {
    position: absolute;
    width: 12px;
    height: 12px;
    transform-origin: 0% 0%;
    transform: rotate(45deg) translate3d(-50%, -50%, 0);
  }

  .Popover__arrow {
    z-index: 1053;
    background-color: #ffffff;
  }

  .Popover__arrowShadow {
    z-index: 1051;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  }

  .Popover__content {
    position: absolute;
    z-index: 1052;
    padding: 10px;
    background-color: #ffffff;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: $border-radius;
  }

  &--top {
    .Popover__arrow,
    .Popover__arrowShadow,
    .Popover__content {
      top: calc(0% - 12px);
      left: 50%;
    }
    .Popover__content {
      transform: translate3d(-50%, -100%, 0);
    }
  }

  &--bottom {
    .Popover__arrow,
    .Popover__arrowShadow,
    .Popover__content {
      top: calc(100% + 12px);
      left: 50%;
    }
    .Popover__content {
      transform: translate3d(-50%, 0, 0);
    }
  }

  &--left {
    .Popover__arrow,
    .Popover__arrowShadow,
    .Popover__content {
      top: 50%;
      left: calc(0% - 12px);
    }
    .Popover__content {
      transform: translate3d(-100%, -50%, 0);
    }
  }

  &--right {
    .Popover__arrow,
    .Popover__arrowShadow,
    .Popover__content {
      top: 50%;
      left: calc(100% + 12px);
    }
    .Popover__content {
      transform: translate3d(0, -50%, 0);
    }
  }
}
</style>
