<template>
  <div class="ProgressIndicator">
    <PulsatingDot
      class="ProgressIndicator__pulsatingDot"
      :color="color"
      :pulsating="progress === Progress.WORKING || progress === Progress.COMPLETE"
    />
    <span class="ProgressIndicator__progressText">{{ text }}</span>
  </div>
</template>

<script>
import Progress from '@types/Progress';
import PulsatingDot, { PulsatingDotColor } from './components/PulsatingDot';

export default {
  name: 'ProgressIndicator',

  components: {
    PulsatingDot,
  },

  enums: {
    Progress,
  },

  props: {
    text: {
      type: String,
      default: '',
    },
    progress: {
      type: String,
      default: '',
    },
  },

  computed: {
    color() {
      switch (this.progress) {
        case Progress.WORKING:
          return PulsatingDotColor.NEUTRAL;
        case Progress.COMPLETE:
          return PulsatingDotColor.SUCCESS;
        case Progress.ERROR:
          return PulsatingDotColor.ERROR;
        default:
          return PulsatingDotColor.NEUTRAL;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ProgressIndicator {
  font-size: 16px;
  height: 1.15em;
  display: flex;
  gap: 0.5em;

  .ProgressIndicator__pulsatingDot {
    font-size: 1em;
    margin-top: 0.15em;
  }

  .ProgressIndicator__progressText {
    white-space: nowrap;
  }
}
</style>
