import { mapActions, mapGetters, mapState } from 'vuex';
import { Action, Getter } from '@store/modules/xal/cart';

export default {
  name: 'XalCartContainer',

  computed: {
    ...mapState('xal/cart', ['lines', 'status', 'updateStatus']),
    ...mapGetters('xal/cart', {
      itemCount: Getter.GET_TOTAL_QUANTITY,
    }),
  },

  created() {
    this.initializeConnection();
    this.startConnection();
  },

  methods: {
    ...mapActions('xal/cart', {
      initializeConnection: Action.INITIALIZE_CONNECTION,
      startConnection: Action.START_CONNECTION,
      removeLine: Action.REMOVE_LINE,
      updateLine: Action.UPDATE_LINE,
    }),
  },

  render() {
    return this.$scopedSlots.default({
      lines: this.lines,
      status: this.status,
      updateStatus: this.updateStatus,
      itemCount: this.itemCount,
      removeLine: this.removeLine,
      updateLine: this.updateLine,
    });
  },

  provide() {
    return {
      XalCartContainer: this,
    };
  },
};
