<template>
  <XalCartContainer v-slot="{ itemCount }">
    <div class="XalMode">
      <XalCartPopover>
        <Button type="system" :count="itemCount">
          <i class="fas fa-shopping-cart" />
        </Button>
      </XalCartPopover>
      <Button type="system" @click.native="stopXal">
        <i class="far fa-sign-out" /> Afbryd XAL
      </Button>
    </div>
  </XalCartContainer>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { Button } from '@components/Buttons';
import XalCartContainer from '@containers/XalCartContainer';
import XalCartPopover from './components/XalCartPopover';

import { addFeatures } from '@plugins/feature-toggle';
import Features from '@types/Features';

const { mapActions } = createNamespacedHelpers('xal');

export default {
  components: {
    Button,
    XalCartContainer,
    XalCartPopover,
  },

  props: {
    session: {
      type: String,
      default: '',
    },
  },

  beforeDestroy() {
    this.$store.unregisterModule('xal');
  },

  created() {
    if (!this.session) return;
    //addFeatures([Features.PRODUCT_STATUS]); // TODO: this is not the best place to add a feature?
    this.startXal(this.session);
    window.addEventListener('keypress', e => {
      if (e.keyCode == 25 && e.ctrlKey) this.stopXal();
    });
  },

  methods: {
    ...mapActions({
      startXal: 'start',
      stopXal: 'stop',
    }),
  },
};
</script>

<style lang="scss" scoped>
.XalMode {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 99999;
  display: flex;
  gap: 20px;
}
</style>
