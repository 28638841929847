<template>
  <div :class="['PulsatingDot', pulsating && 'PulsatingDot--pulsating', `PulsatingDot--${color}`]">
    <div class="PulsatingDot__dot" />
  </div>
</template>

<script>
export const PulsatingDotColor = {
  NEUTRAL: 'neutral',
  SUCCESS: 'success',
  ERROR: 'error',
};

export default {
  name: 'PulsatingDot',

  props: {
    pulsating: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: PulsatingDotColor.NEUTRAL,
    },
  },
};
</script>

<style lang="scss" scoped>
.PulsatingDot {
  font-size: 14px;
  min-width: 1em;
  min-height: 1em;

  .PulsatingDot__dot {
    position: relative;
    top: 25%;
    left: 25%;
    width: 50%;
    height: 50%;
    border-radius: 50%;
  }

  &--neutral .PulsatingDot__dot {
    background-color: #bbbbbb;
  }

  &--success .PulsatingDot__dot {
    background-color: $color-extra-success;
  }

  &--error .PulsatingDot__dot {
    background-color: $color-extra-error;
  }

  .PulsatingDot__dot:before {
    content: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: inherit;
    animation: pulse2 2s ease 0s infinite;
  }

  .PulsatingDot__dot:after {
    content: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: inherit;
    animation: pulse 2s ease 0s infinite;
  }

  &--pulsating .PulsatingDot__dot:before,
  &--pulsating .PulsatingDot__dot:after {
    content: '';
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  70% {
    opacity: 0;
    transform: scale(2.5);
  }
  100% {
    opacity: 0;
    transform: scale(3);
  }
}

@keyframes pulse2 {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  30% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}
</style>
