var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Popover',{attrs:{"toggleOnClick":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._t("default")]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"XalCartPopover__"},[_c('div',{staticClass:"XalCartPopover__header"},[_c('span',{staticClass:"XalCartPopover__headerTitle"},[_vm._v("Indkøbskurv")]),_c('div',{staticClass:"XalCartPopover__headerStatus"},[_c('ProgressStatus',{staticClass:"XalCartPopover__progressStatus",attrs:{"progress":_vm.updateStatus,"completeLabel":"Gemt!","workingLabel":"Gemmer.."}}),_c('ProgressIndicator',{staticClass:"XalCartPopover__progressIndicator",attrs:{"progress":_vm.status,"text":_vm.progressText(_vm.status)}})],1)]),(_vm.lines && _vm.lines.length)?_c('Table',{staticClass:"XalCartPopover__table",attrs:{"itemKey":"line_id","items":_vm.lines}},[_c('TableColumn',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('OrderLineCard',{attrs:{"line":item}})]}}],null,false,3843762655)}),_c('TableColumn',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [(item.item_number)?_c('input',{staticClass:"XalCartPopover__input",attrs:{"disabled":_vm.status !== _vm.Progress.COMPLETE,"name":"amount","type":"number","pattern":"[0-9]","step":"1","maxlength":"4","min":"1"},domProps:{"value":item.quantity},on:{"blur":function($event){item.quantity !== +$event.target.value &&
                _vm.updateLine({ line_id: item.line_id, quantity: +$event.target.value })},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }item.quantity !== +$event.target.value &&
                _vm.updateLine({ line_id: item.line_id, quantity: +$event.target.value })}}}):_vm._e()]}}],null,false,1553522829)}),_c('TableColumn',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var item = ref.item;
return [_c('Button',{attrs:{"color":_vm.colors.RED,"size":_vm.sizes.SMALL,"icon":_vm.icons.TRASH,"disabled":_vm.status !== _vm.Progress.COMPLETE,"tabindex":"-1"},on:{"click":function($event){return _vm.removeLine({ item_id: item.item_id, item_number: item.item_number })}}},[_vm._v(" Fjern ")])]}}],null,false,1980463511)})],1):_c('span',{staticClass:"XalCartPopover__emptyCartLabel"},[_vm._v("Kurven er tom")])],1)]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }