<template>
  <component :is="tag" class="OrderLineCard">
    <div class="OrderLineCard__image">
      <ImageComponent
        v-if="line.product.item_type !== 'placeholder'"
        :src="line.product.image"
        :alt="line.product.title ? line.product.title : ''"
      />
    </div>
    <div class="OrderLineCard__contentWrapper">
      <div class="OrderLineCard__content ft-p3">
        <a
          v-if="line.product.url"
          :href="line.product.url"
          :title="line.product.title"
          class="ft-h5"
        >
          {{ line.product.title }}
        </a>
        <span v-else class="ft-h5">
          {{ line.product.title }}
        </span>
        <span v-if="line.product.item_number">
          {{ $t('product.item_number--short') }}:
          {{ line.product.item_number ? line.product.item_number : '' }}
        </span>
        <template v-if="line.vehicle">
          <a
            :href="`${line.vehicle.url}/t${line.vehicle.id}`"
            class="OrderLineCard__vehicleDescription"
          >
            {{ line.vehicle.description }}
          </a>
          <span v-if="line.vehicle.license_plate">
            <span>{{ $t('vehicle.license_plate--short') }}: </span>
            <span class="OrderLineCard__licensePlate">{{ line.vehicle.license_plate }}</span>
          </span>
          <span v-if="showVehicleId">
            <span>{{ $t('vehicle.id') }}: </span>
            <span class="OrderLineCard__id">{{ line.vehicle.id }}</span>
          </span>
        </template>
      </div>
      <div v-if="hasDefaultSlot" class="OrderLineCard__extraContent">
        <slot name="default" />
      </div>
    </div>
  </component>
</template>

<script>
import ImageComponent from '@components/ImageComponent';

export default {
  name: 'OrderLineCard',

  components: {
    ImageComponent,
  },

  props: {
    tag: {
      type: String,
      default: 'div',
    },
    line: {
      type: Object,
      required: true,
    },
    showVehicleId: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hasDefaultSlot() {
      return !!this.$scopedSlots.default;
    },
  },
};
</script>

<style lang="scss" scoped>
.OrderLineCard {
  display: flex;
  gap: 20px;
  width: 100%;

  .OrderLineCard__image {
    width: 60px;
    min-width: 60px;
  }

  .OrderLineCard__contentWrapper {
    flex: 1;
  }

  .OrderLineCard__content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: flex-start;
  }

  .OrderLineCard__extraContent {
    margin-top: 10px;
  }

  .OrderLineCard__vehicleDescription {
    margin-top: 3px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .OrderLineCard__licensePlate,
  .OrderLineCard__id {
    font-weight: bold;
  }
}
</style>
