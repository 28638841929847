<template>
  <div class="TableComponent">
    <table class="TableComponent__table">
      <thead class="TableComponent__header">
        <TableRow isHeaderRow>
          <slot name="default" />
        </TableRow>
      </thead>
      <transition-group tag="tbody" :css="false" @leave="leave">
        <TableRow
          v-for="(item, index) in items"
          :key="itemKey ? getKey(item) : index"
          :item="item"
          @click.native="clickable && $emit('click', item)"
        >
          <slot name="default" />
        </TableRow>
      </transition-group>
    </table>
  </div>
</template>

<script>
import { gsap } from 'gsap/all';
import { getProperty } from '@helpers/object';
import TableRow from '../TableRow';

export default {
  name: 'Table',

  components: {
    TableRow,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
    itemKey: {
      type: String,
      default: '',
    },
    alignment: {
      type: String,
      default: '',
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    getKey(item) {
      return getProperty(item, this.itemKey);
    },
    getTableCellElements(tableRowElement) {
      return tableRowElement.querySelectorAll('.TableColumn__inner');
    },
    leave(element, done) {
      this.getTableCellElements(element).forEach(element => {
        element.style.overflow = 'hidden';
        gsap.to(element, {
          height: 0,
          paddingTop: 0,
          paddingBottom: 0,
          duration: 0.4,
          overwrite: true,
          onComplete: done,
        });
      });
    },
  },

  provide() {
    return {
      table: this,
    };
  },
};
</script>

<style lang="scss" scoped>
.TableComponent {
  overflow-y: auto;

  .TableComponent__table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }

  .TableComponent__header {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .v-move {
    transition: transform 0.4s;
  }
}
</style>
